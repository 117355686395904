import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BaccaratClassic from "../../../../assets/images/banner/BaccaratClassic.jpg";
import SicBo from "../../../../assets/images/banner/SicBo.jpg";
import RedBlueDuel from "../../../../assets/images/banner/RedBlueDuel.jpg";
import awcthaifishprawncrab from "../../../../assets/images/banner/awcthaifishprawncrab.jpg";
import ExtraAndarBahar from "../../../../assets/images/banner/ExtraAndarBahar.jpg";
import Footer from "../../../../containers/Footer";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../../assets/svg/BackIcon";

const AeSexy = () => {
  const navigate = useNavigate();

  return (
    <>
      <HeaderAfterLogin />

      <main className="main">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center mt-4 PagesHeading">
            <h2 className="">AeSexy</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div className="aesexy-section my-4">
            <ul style={{ justifyContent: "flex-start" }}>
              <li>
                <a href="/casino/awc-baccarat-classic">
                  <img src={BaccaratClassic} />
                </a>
              </li>
              <li>
                <a href="/casino/awc-extra-sicbo">
                  <img src={SicBo} />
                </a>
              </li>
              <li>
                <a href="/casino/awc-red-blue-duel">
                  <img src={RedBlueDuel} />
                </a>
              </li>
              <li>
                <a href="/casino/awc-extra-andar-bahar">
                  <img src={ExtraAndarBahar} />
                </a>
              </li>
              <li>
                <a href="/casino/awc-thai-fish-prawn-crab">
                  <img src={awcthaifishprawncrab} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default AeSexy;
