import React, { useEffect, useState } from "react";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
import Facebook from "../assets/images/facebook-512.svg";
import Instagram from "../assets/images/Instagram.svg";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Container } from "react-bootstrap";

import SupportIcon from "../assets/images/footer/support.png";
import SupportIcon1 from "../assets/images/footer/security.png";
import BottomNav from "./BottomNav";

const Footer = () => {
  const { appDetails } = useSelector((state) => state.app);

  return (
    <footer className="footer">
      <Container>
        <div className="footer-links">
          <ul>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicy ">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegame">Responsible Gaming</a>
            </li>
            <li>
              <a href="/fairplay">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrule">Games Rules</a>
            </li>
            <li>
              <a href="/terms">Terms and Conditions</a>
            </li>
          </ul>
        </div>

        <hr className="my-3"></hr>

        <p className="velkisentence">
          Avatarplays is a product of Avatarplays group which operates in
          accordance with the License granted by SVG Gambling Commission under
          the license # ---- Avatarplays is Powered by Betfair.com
        </p>

        <p>{appDetails?.FOOTER_TEXT || ""}</p>

        <hr className="my-3"></hr>

        <div className="SupportSection">
          <div className="SupportSingleSection">
            <div className="SupportImgIcon">
              <img src={SupportIcon} alt="support" />
            </div>
            <h4>
              Any question? We are <a href="/contactus">here 24/7</a>
            </h4>
            <p>
              To give you the best player experience our support is here to give
              you the answers to your questions any time of the day of the week.
              We are a dedicated and friendly team, and most important we love
              to help.
            </p>
          </div>

          <div className="SupportSingleSection">
            <div className="SupportImgIcon">
              <img src={SupportIcon1} alt="support" />
            </div>
            <h4>A secure gaming site</h4>
            <p>
              Avatarplays is fully licensed and regulated. When you play with
              us, you can be sure that we are following a high standard of
              compliance and that your transactions and details are kept safe
              and secure.
            </p>
          </div>
        </div>

        <div className="social-icons">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </a>
            </li>
            <li>
              <a
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a
                href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src={Facebook} alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>
      </Container>

      <BottomNav />
    </footer>
  );
};

export default Footer;
