import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import LeftBarSports from "../../../components/LeftBarSports";

import EzugiProvider from "../../../../assets/images/banner/Ez.png";
import EvolutionProvider from "../../../../assets/images/banner/EGaming.png";
import VivoProvider from "../../../../assets/images/banner/VivoGaming.png";
import SlotsProvider from "../../../../assets/images/banner/Slots.jpg";
import QtechProvider from "../../../../assets/images/banner/QTech.jpg";
import Kingmaker from "../../../../assets/images/banner/Kingmaker.jpg";
import worldcasino from "../../../../assets/images/banner/worldcasino.png";
import xpg from "../../../../assets/images/banner/xpg.png";
import supernowa from "../../../../assets/images/banner/supernowa.png";
import AESexyGaming from "../../../../assets/images/banner/AeSexy.jpg";

import Footer from "../../../../containers/Footer";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
// import EzugiProvider from "../../../../assets"

const AllCasino = () => {
  const navigate = useNavigate();

  return (
    <>
      <HeaderAfterLogin />

      <main className="main" style={{ height: "100vh" }}>
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center my-4 PagesHeading">
            <h2 className="">All Casino</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div>
            <div className="providerSection mt-4">
              <ul style={{ justifyContent: "flex-start" }}>
                <li>
                  <a href="/casino/ezugi">
                    <img src={EzugiProvider} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution">
                    <img src={EvolutionProvider} />
                  </a>
                </li>
                <li>
                  <a href="/casino/supernowa">
                    <img src={supernowa} />
                  </a>
                </li>
                <li>
                  <a href="/casino/xpg">
                    <img src={xpg} />
                  </a>
                </li>
                <li>
                  <a href="/casino/worldcasino">
                    <img src={worldcasino} />
                  </a>
                </li>
                <li>
                  <a href="/casino/vivo">
                    <img src={VivoProvider} />
                  </a>
                </li>
                <li>
                  <a href="/Slots">
                    <img src={SlotsProvider} />
                  </a>
                </li>
                <li>
                  <a href="/casino/qtech">
                    <img src={QtechProvider} />
                  </a>
                </li>
                <li>
                  <a href="/kingmaker">
                    <img src={Kingmaker} />
                  </a>
                </li>
                <li>
                  <a href="/AeSexy">
                    <img src={AESexyGaming} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default AllCasino;
