import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { socket, socketFunc } from "../../../../utils/socketUtil";
// import BottomNav from "../../../../containers/BottomNav";
// import { pageUrlGenerator } from "../games/DynamicCasinoPage";
import { Navigate, useParams } from "react-router-dom";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import { useSelector } from "react-redux";
import LeftBarSports from "../../../components/LeftBarSports";
// import BottomNav from "../../../../containers/BottomNav";
import { useLocation, useNavigate } from "react-router-dom";

export const PAGE_LOAD_TYPE = {
  PAGE: "page",
  COMPONENT: "component",
};

const ExchangeSPMSportsLobbyPage = ({ loadType = PAGE_LOAD_TYPE.PAGE }) => {
  const [lobbyUrl, setLobbyUrl] = useState();
  const { category } = useParams();
  const { isAuth } = useSelector((state) => state.auth);
  const token = localStorage.getItem("token");

  console.log("category", category);
  useEffect(() => {
    try {
      if (localStorage.getItem("token") !== null) {
        // tab: z.string().optional(),
        // // Layout
        // headless: z.boolean().optional(),
        // sidebarType: z.nativeEnum(SIDERBAR_TYPES).optional(),
        // // Action URLS
        // homeUrl: z.string().url().optional(),
        // depositUrl: z.string().optional(),
        // signUpUrl: z.string().optional(),
        // signInUrl: z.string().optional(),
        // // Sports
        // sportsTypes: z.array(z.number()).optional(), // Allowed sportstypes

        const lobbyConfig = {
          config: {
            launchPage: category,
            // headless: true,
            // tab: "cricket", // soccer, tennis, live, upcomming, favourite,horse
            // tab: "cricket", // soccer, tennis, live, upcomming, favourite,horse

            // homeUrl: pageUrlGenerator("/home"),
            // depositUrl: pageUrlGenerator("/gatewaylist"),
            // signUpUrl: pageUrlGenerator("/mobile-number"),
            // signUsignInUrlpUrl: pageUrlGenerator("/sign-in"),
          },
        };
        socketFunc(token).emit("get:spmsports_lobby", lobbyConfig, (res) => {
          if (typeof res?.data?.lobby === "string") {
            setLobbyUrl(res?.data?.lobby);
          }
        });
      }
    } catch (err) {
      console.error(err);
      return;
    }
  }, [category]);
  const location = useLocation();
  const [isBottomHead, setIsBottomHead] = useState(true);
  useEffect(() => {
    if (location.pathname.startsWith("/spmsports/")) {
      setIsBottomHead(false);
    } else {
      setIsBottomHead(true);
    }
  }, [isBottomHead]);

  const handleFailed = () => {
    if (loadType === PAGE_LOAD_TYPE.PAGE) {
      return <Navigate to="/home" />;
    } else {
      return (
        <div className="custom_placeholder_parent">
          <div className="custom_placeholder_child">
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="primary" size="sm" />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {/* <div className="header-bg">
        <HeaderAfterLogin />
      </div> */}

      <div className="exchangeLobby">
        <div className="live_casino_page live">
          {!Boolean(lobbyUrl) ? (
            <div className="custom_placeholder_parent">
              <div className="custom_placeholder_child">
                <Spinner animation="grow" variant="primary" size="lg" />
                <Spinner animation="grow" variant="primary" size="lg" />
                <Spinner animation="grow" variant="primary" size="lg" />
              </div>
            </div>
          ) : (
            <div
              className={
                isBottomHead ? "exchange_iframe" : "exchange_iframe mt100"
              }
            >
              <iframe
                title="Exchange"
                src={lobbyUrl}
                frameBorder="0"
                // scrolling="no"
                allowFullScreen={true}
              >
                Your browser doesn't support iFrames.
              </iframe>
            </div>
          )}
          {/*  Iframe End */}
        </div>
      </div>
    </>
  );
};

export default ExchangeSPMSportsLobbyPage;
