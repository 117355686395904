import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/images/logo.webp";
import SportsIcon from "../assets/images/games-icon/basketball.png";
import BotHeadIcon1 from "../assets/images/games-icon/In-play.svg";
import CasinoIcon from "../assets/images/games-icon/allcasino.png";
import BotHeadIcon2 from "../assets/images/games-icon/cricketicon.png";
import BotHeadIcon3 from "../assets/images/games-icon/footballicon.png";
import BotHeadIcon4 from "../assets/images/games-icon/aviator.gif";
import Tennis from "../assets/images/games-icon/Tennis.svg";
import HorseRacing from "../assets/images/games-icon/HorseRacing.svg";
import XPG from "../assets/images/games-icon/XG.svg";
import livetv from "../assets/images/games-icon/livetv-icon.png";
import BotHeadIcon5 from "../assets/images/games-icon/dragon-tiger1.png";
import BotHeadIcon6 from "../assets/images/games-icon/Teen-patti.png";
import BotHeadIcon7 from "../assets/images/games-icon/roulette.png";
import SportsIcon1 from "../assets/images/games-icon/Exchange.png";
import HomeIcon1 from "../assets/images/games-icon/home-icon1.png";
import EzugiIcon from "../assets/images/games-icon/Ezugi.png";
import SlotsnIcon from "../assets/images/games-icon/slots.png";
import CashierIcon1 from "../assets/images/games-icon/cashier.png";
import { useSelector } from "react-redux";
import BotHeadIcon10 from "../assets/images/games-icon/table-tennis-equipmen.png";
import BotHeadIcon11 from "../assets/images/games-icon/slot-machine.png";
import login from "../assets/images/icons/login.svg";
import signup from "../assets/images/icons/sign.png";
import { getNotifications } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";
// import BotHeadIcon4 from "../assets/images/games-icon/Aviator.png";

const Header = () => {
  const navigate = useNavigate();
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const { appDetails } = useSelector((state) => state.app);

  useEffect(() => {
    loadNotifications();
  }, []);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }} key={idx}>
                {notf?.content}
              </label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <header className="header beforeheader">
        <div className="top_head">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                      {/* <img src={logo} alt="Logo" /> */}
                    </a>
                  </div>
                  <div className="bottom_head">
                    <Container fluid>
                      <Row>
                        <Col xs={12} className="">
                          <ul>
                            <li>
                              <a href="/mobile-number">
                                <img
                                  src={SportsIcon}
                                  alt="bottom head link icon"
                                />
                                <span>Exchange</span>
                              </a>
                            </li>
                            <li>
                              <a href="/mobile-number">
                                <img
                                  src={SportsIcon}
                                  alt="bottom head link icon"
                                />
                                <span>Sports</span>
                              </a>
                            </li>
                            <li>
                              <a href="/mobile-number">
                                <img
                                  src={CasinoIcon}
                                  alt="bottom head link icon"
                                />
                                <span>All Casino</span>
                              </a>
                            </li>
                            <li>
                              <a href="/Slots">
                                <img
                                  src={BotHeadIcon11}
                                  alt="bottom head link icon"
                                />
                                <span>Slots</span>
                              </a>
                            </li>
                            <li>
                              <a href="/casino/spribe/aviator">
                                <img
                                  src={BotHeadIcon4}
                                  alt="bottom head link icon"
                                />
                                <span>Aviator</span>
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Container>
                  </div>

                  <div className="headerRight ">
                    <div className="login-signup-btn">
                      <Button
                        onClick={() => {
                          navigate("/sign-in");
                        }}
                        className=""
                      >
                        <span>Log In</span>
                      </Button>
                    </div>
                    <div className="login-signup-btn BonusAnimation">
                      <Button
                        variant="primary"
                        onClick={() => {
                          navigate("/mobile-number");
                        }}
                        className="button-primary btn_signup ms-2"
                      >
                        <span>Register</span>
                      </Button>
                      <div className="authorization__animation-item firstChild"></div>
                      <div className="authorization__animation-item secondChild"></div>
                      <div className="authorization__animation-item thirdChild"></div>
                      <div className="authorization__animation-item fourthChild"></div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
};

export default Header;
